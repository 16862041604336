
import { defineComponent, onMounted } from "vue";
import AssessoreWithCourse from "@/views/Pending/list/AssessoreWithCourse.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    AssessoreWithCourse,
  },
  data() {
    return {
      data: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
